// https://blog.csdn.net/huangpb123/article/details/81490046
// https://www.jianshu.com/p/ee052f48aeae

import React from 'react';
import ImageSpin from '../assets/spin-1s-48px.svg';

const MyLoading = ({ isLoading, error }) => {
  // const { clientWidth, clientHeight } = window.document.body;
  // Handle the loading state
  if (isLoading) {
    return (
      <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(236, 240, 245)' }}>
        <img
          style={{ position: 'absolute', left: '48%', top: '48%' }}
          src={ImageSpin}
          alt="加载中，请稍等"
        />
      </div>
    );
  }
  // Handle the error state
  if (error) {
    return (
      <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(236, 240, 245)' }}>
        <span style={{ position: 'absolute', left: '48%', top: '48%' }}>很抱歉，网页加载失败</span>
      </div>
    );
  }
  return null;
};

export default MyLoading;

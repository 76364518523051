// https://reactjs.org/docs/optimizing-performance.html#using-immutable-data-structures

// types
const types = {
  SYS_TOKEN: 'SYS/TOKEN',
};

// state
const initialState = {
  token: 'token',
};

// action creators
const actions = {
  setToken: (d) => ({
    type: types.SYS_TOKEN,
    payload: d,
  }),
};

// reducers
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SYS_TOKEN:
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

// export
export {
  initialState, types, actions, reducers,
};

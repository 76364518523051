// window.localStorage
import { CONST_AES } from './constants';
import { aes } from '../util';

const locals = {
  init() {
    const kList = [
      'accessToken',
      'userId',
      'userName',
      'userRole',
      'orgId',
      'orgName',
      'accessTime',
      'selectedMenuItem',
      'openMenuItems',
      'navExpanded',

      'categories', // 行业分类
      'submitId', // 上次提交
    ];

    kList.forEach((k) => {
      const k2 = aes.cryptoAesEnc(k, CONST_AES.KEY, CONST_AES.IV);
      window.localStorage.removeItem(k2);
    });

    const k3 = aes.cryptoAesEnc('accessTime', CONST_AES.KEY, CONST_AES.IV);
    const v3 = aes.cryptoAesEnc(new Date().getTime().toString(), CONST_AES.KEY, CONST_AES.IV);
    window.localStorage.setItem(k3, v3);
  },
  set(k, v) {
    const k2 = aes.cryptoAesEnc(k, CONST_AES.KEY, CONST_AES.IV);
    const v2 = aes.cryptoAesEnc(v, CONST_AES.KEY, CONST_AES.IV);
    window.localStorage.setItem(k2, v2);
  },
  remove(k) {
    const k2 = aes.cryptoAesEnc(k, CONST_AES.KEY, CONST_AES.IV);
    window.localStorage.removeItem(k2);
  },
  get(k) {
    const k2 = aes.cryptoAesEnc(k, CONST_AES.KEY, CONST_AES.IV);
    const v2 = window.localStorage.getItem(k2);
    if (v2) {
      return aes.cryptoAesDec(v2, CONST_AES.KEY, CONST_AES.IV);
    }

    return '';
  },
};

// export
export default locals;

// aes
const CONST_AES = Object.freeze({
  KEY: '$1qaz2wsx3edc4r$',
  IV: '$1qaz2wsx3edc4r$',
});

// http
const CONST_HTTP = Object.freeze({
  // common
  TOKEN_RENEW_URL: '/admin/v1/user/token-renew',

  // local
  // HTTP_ROOT: 'http://127.0.0.1:9000',
  // HTTP_ROOT: 'http://192.168.0.188:8083',

  // production
  HTTP_ROOT: '/api',
});

const REPORT_NAME_MAP = {
  'MA-BS': 'MA-资产负债表',
  'MB-IS': 'MB-利润表',
  'MC-CF': 'MC-现金流量表',
  'MT-BIC': 'MT',
};

export { CONST_AES, CONST_HTTP, REPORT_NAME_MAP };

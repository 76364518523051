import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducers as ui } from './ui';
import { reducers as sys } from './sys';
import { reducers as cache } from './cache';

// rootReducre
const rootReducer = combineReducers({
  ui,
  sys,
  cache,
});

// store
const configureStore = () => createStore(rootReducer, applyMiddleware(thunk));
const store = configureStore();

export default store;

// import 'babel-polyfill';
// import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Provider from 'react-redux/es/components/Provider';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { ConfigProvider } from 'antd';
// eslint-disable-next-line camelcase
import zh_CN from 'antd/lib/locale-provider/zh_CN';

import store from './redux';
// import Login from './containers/Login';
// import App from './containers/App';
import MyLoading from './containers/MyLoading';

import 'antd/dist/antd.css';
import './index.css';

const AsyncLogin = Loadable({
  loader: () => import('./containers/Login'),
  loading: MyLoading,
});
const AsyncPage403 = Loadable({
  loader: () => import('./containers/Page403'),
  loading: MyLoading,
});
const AsyncPage404 = Loadable({
  loader: () => import('./containers/Page404'),
  loading: MyLoading,
});

const AsyncApp = Loadable({
  loader: () => import('./containers/App'),
  loading: MyLoading,
});

ReactDOM.render(
  <Provider store={store}>
    {/* eslint-disable-next-line camelcase */}
    <ConfigProvider locale={zh_CN}>
      <Router>
        <Switch>
          <Route exact path="/" component={AsyncLogin} />
          <Route exact path="/403" component={AsyncPage403} />
          <Route exact path="/404" component={AsyncPage404} />
          <Route path="*" component={AsyncApp} />
        </Switch>
      </Router>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);

// AES
import crypto from 'crypto-js';

// aes 加密
const cryptoAesEnc = (data, key, iv) => {
  const encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(data), crypto.enc.Utf8.parse(key), {
    iv: crypto.enc.Utf8.parse(iv),
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  });

  return encrypted.toString();
};

// aes 解密
const cryptoAesDec = (data, key, iv) => {
  const decrypted = crypto.AES.decrypt(data, crypto.enc.Utf8.parse(key), {
    iv: crypto.enc.Utf8.parse(iv),
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  });

  return crypto.enc.Utf8.stringify(decrypted);
};

export { cryptoAesEnc, cryptoAesDec };

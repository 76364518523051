// types
import locals from '../data/locals';

const types = {
  UI_NAV_TOGGLE: 'ui/UI_NAV_TOGGLE',
  UI_MENU_ITEM_CHANGED: 'ui/UI_MENU_ITEM_CHANGED', // 选中的菜单
  UI_MENU_OPEN_CHANGED: 'ui/UI_MENU_OPEN_CHANGED', // 打开的菜单
  UI_CLIENT_WIDTH: 'ui/UI_CLIENT_WIDTH',
  UI_CLIENT_HEIGHT: 'ui/UI_CLIENT_HEIGHT',
  UI_NOTICE_UNREAD_COUNT: 'ui/UI_NOTICE_UNREAD_COUNT',
};

// state
const initialState = {
  navExpanded: locals.get('navExpanded') ? JSON.parse(locals.get('navExpanded')) : true,
  selectedMenuItem: locals.get('selectedMenuItem') ? locals.get('selectedMenuItem') : 'dashboard',
  openMenuItems: locals.get('openMenuItems') ? JSON.parse(locals.get('openMenuItems')) : [],
  clientWidth: window.document.body.clientWidth,
  clientHeight: window.document.body.clientHeight,
  noticeUnreadCount: 1,
};

// action creators
const actions = {
  toggleNav: () => ({
    type: types.UI_NAV_TOGGLE,
  }),
  setSelectedMenuItem: (payload) => ({
    type: types.UI_MENU_ITEM_CHANGED,
    payload,
  }),
  setOpenMenuItems: (payload) => ({
    type: types.UI_MENU_OPEN_CHANGED,
    payload,
  }),
  setClientWidth: (payload) => ({
    type: types.UI_CLIENT_WIDTH,
    payload,
  }),
  setClientHeight: (payload) => ({
    type: types.UI_CLIENT_HEIGHT,
    payload,
  }),
  setNoticeUnreadCount: (payload) => ({
    type: types.UI_NOTICE_UNREAD_COUNT,
    payload,
  }),
};

// reducers
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.UI_NAV_TOGGLE:
      locals.set('navExpanded', JSON.stringify(!state.navExpanded));
      return { ...state, navExpanded: !state.navExpanded };
    case types.UI_MENU_ITEM_CHANGED:
      locals.set('selectedMenuItem', action.payload);
      return { ...state, selectedMenuItem: action.payload };
    case types.UI_MENU_OPEN_CHANGED:
      locals.set('openMenuItems', JSON.stringify(action.payload));
      return { ...state, openMenuItems: action.payload };
    case types.UI_CLIENT_WIDTH:
      return { ...state, clientWidth: action.payload };
    case types.UI_CLIENT_HEIGHT:
      return { ...state, clientHeight: action.payload };
    case types.UI_NOTICE_UNREAD_COUNT:
      return { ...state, noticeUnreadCount: action.payload };
    default:
      return state;
  }
};

// export
export {
  initialState, types, actions, reducers,
};

// types
import locals from '../data/locals';

const types = {
  ORG_LIST: 'cache/org_list',
};

// state
const initialState = {
  orgList: locals.get('orgList') ? JSON.parse(locals.get('orgList')) : [],
};

// action creators
const actions = {
  setOrgList: (d) => ({
    type: types.ORG_LIST,
    payload: d,
  }),
};

// reducers
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.ORG_LIST:
      return { ...state, orgList: action.payload };
    default:
      return state;
  }
};

// export
export {
  initialState, types, actions, reducers,
};
